import { createApp } from 'vue'
import router from './router';//添加router
// 添加antUI组件
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import DataVVue3 from '@kjgl77/datav-vue3'

import App from './App.vue'
import axios from 'axios';

const app = createApp(App)
app.config.globalProperties.$http = axios;

app.use(DataVVue3)
app.use(router)
app.use(Antd)
app.mount('#app')

