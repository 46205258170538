<template>
  <router-view></router-view>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const lastRoute = ref('');

const router_get = () => {
  router.beforeEach((to, from, next) => {
    // 检查路由是否需要身份验证
    if (to.meta.requiresAuth) {
      if (lastRoute.value) {
        next();
      } else {
        // 如果未登录，则重定向到登录页面
        // next({ name: "Login1" });
      }
    } else {
      next();
    }
  });
};

onMounted(() => {
  login();
});

const login = async () => {
  const value = localStorage.getItem("userdata");
  lastRoute.value = localStorage.getItem("lastRoute");
  
  if (value && !lastRoute.value) { // 修正这里的检查
    await router.replace({ name: "IndexComponent1" });
  } else if (value && lastRoute.value) {
    router_get();
  } else {
    await router.replace({ name: "Login1" });
  }
};
</script>

<style></style>