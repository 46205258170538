import { createRouter,createWebHashHistory } from "vue-router";
const routes = [
    {
        name:'Login1',
        path:'/Login',
        component:()=> import('../components/Login.vue')
    },
    {
        name:'IndexComponent1',
        path:'/IndexComponent',
        component:()=> import('../components/IndexComponent.vue'),
        meta: { requiresAuth: true },//验证用户登录信息
        children:[
            {
                name:'HomePageData1',//主页数据
                path:'/HomePageData',
                component:()=> import('../pages/HomePageData/HomePageData.vue')
            },
            {
                name:'RoleManagement1',//角色管理
                path:'/RoleManagement',
                component:()=>import('../pages/RoleManagement/RoleManagement.vue')
            },
            {
                name:'FrequencyManagement1',//次数管理
                path:'/FrequencyManagement',
                component:()=>import('../pages/FrequencyManagement/FrequencyManagement.vue')
            },
            {
                name:'UserManagement1',//用户管理
                path:'/UserManagement',
                component:()=>import('../pages/UserManagement/UserManagement.vue')
            },
            {
                name:'TutorialManagement1',//教程管理
                path:'/TutorialManagement',
                component:()=>import('../pages/SystemManagement/TutorialManagement.vue')
            },
            {
                name:'ConsultingManagement1',//客服管理
                path:'/ConsultingManagement',
                component:()=>import('../pages/SystemManagement/ConsultingManagement.vue')
            },
            {
                name:'MaPai_guanli1',//码牌管理
                path:'/MaPai_guanli',
                component:()=>import('../pages/SystemManagement/MaPai_guanli.vue')
            },
            {
                name:'Pay_guanli1',//支付管理
                path:'/Pay_guanli',
                component:()=>import('../pages/SystemManagement/Pay_guanli.vue')
            },
            {
                name:'Lunbo1',//轮播
                path:'/Lunbo',
                component:()=>import('../pages/SystemManagement/Lunbo.vue')
            },
            {
                name:'WalletManagement1',//钱包管理
                path:'/WalletManagement',
                component:()=>import('../pages/WalletManagement/WalletManagement.vue')
            },
            {
                name:'PackageManagement1',//套餐管理
                path:'/PackageManagement',
                component:()=>import('../pages/WalletManagement/PackageManagement.vue')
            },
            {
                name:'order_management1',//订单管理
                path:'/order_management',
                component:()=>import('../pages/WalletManagement/order_management.vue')
            },
            {
                name:'withdrawal_application_management1',//提现申请管理
                path:'/withdrawal_application_management',
                component:()=>import('../pages/WalletManagement/withdrawal_application_management.vue')
            },
            {
                name:'operational_statistics1',//运营统计
                path:'/operational_statistics',
                component:()=>import('../pages/WalletManagement/operational_statistics.vue')
            },
            {
                name:'SystemManagement1',//系统管理
                path:'/SystemManagement',
                component:()=>import('../pages/SystemManagement/SystemManagement.vue')
            },
            {
                name:'CodePolymerization1',//活码聚合
                path:'/CodePolymerization',
                component:()=>import('../pages/FunctionManagement/CodePolymerization.vue')
            },
            {
                name:'FriendCode1',//好友活码
                path:'/FriendCode',
                component:()=>import('../pages/FunctionManagement/FriendCode.vue')
            },
            {
                name:'Group_chat_code1',//群聊活码
                path:'/Group_chat_code',
                component:()=>import('../pages/FunctionManagement/Group_chat_code.vue')
            },
            {
                name:'Lunxun_code1',//轮询活码
                path:'/Lunxun_code',
                component:()=>import('../pages/FunctionManagement/Lunxun_code.vue')
            },
            {
                name:'LinkTranscoding1',//链接转码
                path:'/LinkTranscoding',
                component:()=>import('../pages/FunctionManagement/LinkTranscoding.vue')
            },
            {
                name:'code_to_link1',//链接转码
                path:'/code_to_link',
                component:()=>import('../pages/FunctionManagement/code_to_link.vue')
            },
            {
                name:'tupian_yasuo1',//图片压缩
                path:'/tupian_yasuo',
                component:()=>import('../pages/FunctionManagement/tupian_yasuo.vue')
            },
        ]
    },
    {
        // 默认根路径跳转到Login
        path: '/',
        redirect: '/Login'
      },
];
const router = createRouter({
    // 创建路由的哈希模式
    history:createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // 保存目标路径到 localStorage
    localStorage.setItem('lastRoute', to.fullPath);
    next();
  });




export default router;